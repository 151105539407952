import OfferBanner from './offer-banner';
import OfferCard from './offer-card';

const OfferComponent = (props: any) => {
  const { dealsData, dealsBannerData } = props;
  const specialsTitle = dealsBannerData?.attributes?.Section[2]?.Title;
  return (
    <>
      <OfferBanner dealsBannerData={dealsBannerData} />
      <OfferCard dealsData={dealsData} specialsTitle={specialsTitle} />
    </>
  );
};
export default OfferComponent;
