import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useContext, useEffect } from 'react';

import CanonicalURL from '@/components/common/Canonical';
import OfferComponent from '@/components/offer-section/offer-component';
import { getSEOProps } from '@/components/utility';
import HeaderFooterContext from '@/context/HeaderFooterContext';
import { getDealsData } from '@/services/deals';
import { getInnerPagesWithDefaultLocale } from '@/services/home';
import SetBrandsContext from '@/services/home/setBrands';
import {
  BRAND_NAME_EN,
  BRAND_NAME_FR,
  DEALS_CMS_SLUG,
  DEALS_CMS_SLUG_FR,
} from '@/utils/constants';

const Offers = (props: any) => {
  const { dealsData, dealsBannerData } = props;
  const router = useRouter();
  const seoProps = getSEOProps(dealsBannerData?.attributes?.SEO, router);
  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;
  const { locale }: any = useRouter();

  const isContext = !!headerFooter[0];

  const getContextData = async () => {
    let defaultBrand = BRAND_NAME_EN;
    if (locale === 'fr') defaultBrand = BRAND_NAME_FR;
    const contextData = await SetBrandsContext(defaultBrand, locale);
    const brands = contextData?.data;
    headerFooterData.setHeaderFooterData(brands);
  };
  useEffect(() => {
    getContextData();
    if (sessionStorage.getItem('offCords')) {
      sessionStorage.removeItem('offCords');
    }
  }, [isContext, locale]);
  return (
    <div className="sv-page-content deals-wrapper">
      <NextSeo {...seoProps} />
      <CanonicalURL />
      <OfferComponent dealsData={dealsData} dealsBannerData={dealsBannerData} />
    </div>
  );
};

export async function getServerSideProps(props: any) {
  const locale = props?.locale;
  let brand = BRAND_NAME_EN;
  let defaultLink = DEALS_CMS_SLUG;
  if (locale === 'fr') {
    brand = BRAND_NAME_FR;
    defaultLink = DEALS_CMS_SLUG_FR;
  }
  const dealsRes = await getDealsData(brand, locale);
  const dealsData = (await dealsRes?.data?.data) || null;
  const dealsBannerRes = await getInnerPagesWithDefaultLocale(
    defaultLink,
    brand,
    locale
  );

  const dealsBannerData = (await dealsBannerRes?.data?.data[0]) || null;
  return { props: { dealsData, dealsBannerData } };
}

export default Offers;
