import Hero from '../hero-banner/hero';

const OfferBanner = (props: any) => {
  const dealsData = props?.dealsBannerData?.attributes?.Section;
  const imgUrl = dealsData[0]?.Background_Image?.data.attributes?.url;
  const imgUrlAlt =
  dealsData[0]?.Background_Image?.data.attributes?.alternativeText ||
  dealsData[0]?.Background_Image?.data.attributes?.name;
  const title = dealsData[0]?.Title;
  const subTitle = dealsData[0]?.Sub_Title;

  const ctaTitle = dealsData && dealsData[1].Title;
  const ctaInfo = dealsData && dealsData[1].Link;

  const ctaTextNumber = ctaInfo && ctaInfo.Text;
  const ctaPhoneNumber = ctaInfo && ctaInfo.Slug_or_URL;

  return (
    <div className="container-xl custom-container px-0">
      <section className="hero-section">
        <Hero imgUrl={imgUrl}
        imgUrlAlt={imgUrlAlt} title={title} subTitle={subTitle} />
        <div className="promotion-banner">
          {ctaTitle && ctaTextNumber && (
            <div className="container-fluid">
              <p className="sv-white-bold-text">
                {ctaTitle}{' '}
                <a className="text-white cursor-pointer" href={ctaPhoneNumber}>
                  {ctaTextNumber}
                </a>{' '}
              </p>{' '}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default OfferBanner;
