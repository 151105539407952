import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/future/image';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

const OfferCard = (props: any) => {
  const { dealsData, specialsTitle } = props;

  return (
    <section className="current-specials">
      <div className="container">
        <h2 className="section-heading">{specialsTitle}</h2>

        <div className="row justify-content-center g-3">
          {dealsData &&
            dealsData.length &&
            dealsData.map((item: any, index: any) => {
              const slug = item?.attributes?.Slug
              let as =
                slug === 'self-storage-referral-program-se'
                  ? 'referral/'
                  : `offers/${slug}`;
              let href =
                slug === 'self-storage-referral-program-se'
                  ? '/referral'
                  : '/offers/[offer]';
              as =
                  slug === 'recommandation-entreposage-libre-service-se'
                    ? 'recommandation/'
                    : as;
              href =
                  slug === 'recommandation-entreposage-libre-service-se'
                    ? '/recommandation'
                    : href;
              return (
                <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                  <div className="card sv-solution-card">
                    <div className="card-body p-0">
                      <div className="row g-0">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-8">
                          <div className="card-body-padding">
                            <h5 className="card-title">
                              {item?.attributes?.Title}
                            </h5>
                            <div
                              className="card-text"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  item?.attributes?.Excerpt
                                ),
                              }}
                            ></div>
                          </div>
                          <div className="card-footer">
                          <Link href={href} as={as}>
                              <a className="card-link link">
                                <FormattedMessage id="blogs-learn-more" />{' '}
                                {'>>'}
                              </a>
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                          <Image
                            className="card-img"
                            src={
                              item?.attributes?.Thumbnail?.data?.attributes?.url
                            }
                            alt={
                              item?.attributes?.Thumbnail?.data?.attributes
                                ?.alternativeText
                            }
                            sizes="(max-width: 375px) 85px, (max-width: 576px) 135px, (max-width: 768px) 110px, (max-width: 992px) 145px, (max-width: 1280px) 175px"
                            width={180}
                            height={150}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default OfferCard;
