import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/future/image';

import ButtonLink from '../packing-supplies/button-link';

const Hero = (props: any) => {
  const imgUrl = props?.imgUrl || '';
  const imgUrlAlt = props?.imgUrlAlt || 'common-banner';
  const title = props?.title || '';
  const subTitle = props?.subTitle || '';
  const linkData = props?.linkData || null;
  return (
    <div className="container-xl custom-container px-0">
      <section className="hero-section">
        <div className="hero-banner">
          <Image
            sizes="(max-width: 375px) 320px, (max-width: 576px) 576px, (max-width: 768px) 768px, (max-width: 992px) 992px, (max-width: 1280px) 1280px"
            src={imgUrl}
            alt={imgUrlAlt}
            className="banner-img"
            width="1280"
            height="516"
          />
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 m-auto">
                  <h1>{title}</h1>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subTitle),
                    }}
                  ></h6>
                </div>
              </div>
              {linkData ? <ButtonLink link={linkData}></ButtonLink> : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
