import Link from 'next/link';

import { ORDER_LINK } from '@/utils/constants';

const ButtonLink = (props: any) => {
  const lintText = props?.link?.Text;
  const link = props?.link?.Slug_or_URL || ORDER_LINK;
  const target = props?.link?.Target || '';

  let btnClass = 'btn order-online-btn se-primary-btn';
  if (props?.btnClass) {
    btnClass = `${btnClass} ${props?.btnClass}`;
  }

  return (
    <div className="order-online">
      <Link href={link}>
        <a target={target}>
        <button className={btnClass} type="button">
            {lintText}
          </button>
        </a>
      </Link>
    </div>
  );
};

export default ButtonLink;
